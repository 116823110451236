import { initializeApp } from 'firebase/app';
import { beforeAuthStateChanged, getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import { getDatabase, child, ref, get, onValue, update, onDisconnect } from 'firebase/database';
import config, { authConfig } from '../firebase.config';
import axios from 'axios';


class FirebaseService {
  main: any;
  authentication: any;

  db: any;
  toiboxDb: any;
  auth: any;

  init(success: any) {
    try {
      this.main = initializeApp(config);
      this.authentication = initializeApp(authConfig, 'authFirebase');

      this.db = getDatabase(this.main);
      this.auth = getAuth(this.authentication);
      this.toiboxDb = getDatabase(this.authentication);
    }
    catch (err) {
      console.log(err);
      success(false);
    }
    success(true);
  }

  getUserData = (uid: any): Promise<any> => {
    const db = this.db;
    console.log('uid', uid);
    return new Promise<any>((resolve, reject) => {
      get(child(ref(db), `users/${uid}`)).then(async (snapshot) => {
        if (snapshot.exists()) {
          const user = snapshot.val();
          console.log('user', user);
          resolve({ user });

        } else {
          reject('No user data available');
        }
      }).catch((error) => {
        reject(error);
      });
    });
  };

  beforeAuthStateChanged = (callback: any) => {
    return beforeAuthStateChanged(this.auth, callback);
  };

  onAuthStateChanged = (callback: any) => {
    return onAuthStateChanged(this.auth, callback);
  };

  signInWithToken = async (session: any, getCookie: any, removeCookie: any) => {
    if (!session) return null;

    // validate session
    const response = await axios.post(`${process.env.REACT_APP_FANTASY_API_URL}/system/verifysession`, { toiboxToken: session });

    const { data } = response;

    if (data.code === 100) {
      signInWithCustomToken(this.auth, data.newToken);
      return true;
    } else {
      removeCookie('session');
      return false;
    }
  };

  onConnected = (uid: string) => {
    const dbRef = ref(this.db, '.info/connected');
    const userStatusDatabaseRef = ref(this.db, `/users/${uid}`);

    const isOfflineForDatabase = { status: 'offline' };

    const isOnlineForDatabase = { status: 'online' };

    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();

      if (!data) return;

      onDisconnect(userStatusDatabaseRef)
        .update(isOfflineForDatabase)
        .then(() => {
          update(userStatusDatabaseRef, isOnlineForDatabase);
        });
    });

  };

  signOut = (temp: any) => {
    if (!this.auth) return;

    const uid = this.auth.currentUser?.uid;
    update(ref(this.db, `/users/${uid}`), { status: 'offline' });

    this.auth.signOut();
  };
}

const instance = new FirebaseService();

export default instance;
