import { useLayoutEffect } from 'react';
import Cookies, { CookieChangeListener } from 'universal-cookie';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, AppState } from './store';


export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

// COOKIES
const globalCookies = new Cookies();

export const useCookies = () => {

  useLayoutEffect(() => {
    const onChange: any = ((change: CookieChangeListener) => {
      const { name, value }: any = change;

      if (name === 'session' && !value) {
        //dispatch(logout({}));
      }
    });

    globalCookies.addChangeListener(onChange);

    return () => {
      globalCookies.removeChangeListener(onChange);
    };
  }, []);

  return {
    setCookie: globalCookies.set.bind(globalCookies),
    getCookie: globalCookies.get.bind(globalCookies),
    removeCookie: globalCookies.remove.bind(globalCookies),
    cookies: globalCookies.getAll(),
  };
};