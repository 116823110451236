import "./App.css";
import PublicView from "./components/avatar";
import Auth from './auth/Auth';


function App() {
  return (
    <div className="App">
      <Auth>
        <PublicView />
      </Auth>
    </div>
  );
}

export default App;
