import { createUserWithEmailAndPassword, updateProfile, updateEmail, updatePassword, EmailAuthProvider, reauthenticateWithCredential, deleteUser } from 'firebase/auth';
import { ref, get, child, set, off } from 'firebase/database';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import FirebaseService from '../../services/FirebaseService';
// import { getQuestions } from 'reducers/game/QuestionsSlice';
// import { setGame } from 'reducers/game/GamesSlice';
import { uuidv4 } from '@firebase/util';
import axios from 'axios';


export const submitSignin = ({ email, password, username }: any) => async (dispatch: any) => {
  const auth: any = FirebaseService.auth;

  return await createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential: any) => {
      const user = userCredential.user;

      updateProfile(user, { displayName: username });
      dispatch(setIsNewUser(true));
      dispatch(setUserDataFirebase({
        authUser: {
          ...user,
          displayName: username,
        },
      }));

      await axios.post(`${process.env.REACT_APP_FANTASY_API_URL}/user/signin`, { toiboxToken: user.accessToken });

      return userCredential;
    });
  // .catch((error) => ({
  //   code: 400,
  //   message: error.message,
  // }));
};

export const updateProfilePicture = ({ photoURL }: any) => async (dispatch: any) => {
  const auth: any = FirebaseService.auth;

  return updateProfile(auth.currentUser, { photoURL })
    .then(() => ({ code: 200 }))
    .catch((error) => ({
      code: 400,
      message: error.message,
    }));
};

export const setUserDataFirebase = createAsyncThunk(
  'setUserDataFirebase',
  async ({ authUser }: any, { dispatch }) => {
    const db = FirebaseService.db;
    const { uid } = authUser;

    const snapshot = await get(child(ref(db), `/users/${uid}`));
    const user = snapshot.val();
    const data = {
      photoURL: user.photoURL ?? null,
      loggedIn: true,
      displayName: user.displayName,
      uid
    };
    console.log('setting user data:', data);
    return data;
  },
);

export const deleteAccount = createAsyncThunk('deleteAccount', async (params: any, { dispatch, getState }: any) => {
  const user = FirebaseService.auth.currentUser;
  const { sys } = getState().system;

  await deleteUser(user);

  const response = await axios.post(`${process.env.REACT_APP_FANTASY_API_URL}/user/deleteaccount`, { toiboxToken: sys.accessToken });

  const data = await response.data;

  if (data.code === 100) {
    dispatch(logout({}));
    return true;
  } else {
    return false;
  }

});

export const logout = createAsyncThunk('logout', async ({ params }: any, { dispatch, getState }: any) => {
  const db = FirebaseService.db;
  const { uid } = getState().auth.user;

  off(ref(db, `users/${uid}/toibucks`));

  FirebaseService.signOut(null);

  return dispatch(userLoggedOut());
});

export const updateProfileData = ({ username, email, phoneNumber }: any) => async (dispatch: any) => {
  const { currentUser } = FirebaseService.auth;

  const result = await Promise.all([updateProfile(currentUser, { displayName: username }), updateEmail(currentUser, email)]).then(() => ({ code: 200 }))
    .catch((error) => ({
      code: 400,
      message: error.message,
    }));

  return result;
};

export const changeUserPassword = createAsyncThunk('changeUserPassword', async ({ oldPassword, newPassword }: any, { dispatch }) => {
  const { currentUser } = FirebaseService.auth;
  try {
    // reauthenticating
    await reauthenticate(oldPassword);
    // updating password
    await updatePassword(currentUser, newPassword);
    return 200;
  } catch (err) {
    return 404;
  }
});

const reauthenticate = (currentPassword: string) => {
  const { currentUser } = FirebaseService.auth;
  const credentials = EmailAuthProvider.credential(currentUser.email, currentPassword);
  return reauthenticateWithCredential(currentUser, credentials);
};

/* always load this game every refresh */
export const setUserCurrentGame = createAsyncThunk('user/setUserCurrentGame', async (game: any, { dispatch }) => {
  const db = FirebaseService.db;
  const { currentUser } = FirebaseService.auth;
  const uid = currentUser?.uid ?? 0;

  set(ref(db, `users/${uid}/gameID`), game.key);

  return true;
});


const initialState = {
  email: '',
  uid: uuidv4(),
  displayName: '',
  avatar: '',
  loggedIn: false,
  isNewUser: false,
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedOut: () => initialState,
    setIsNewUser: {
      reducer: (state, action: any) => {
        state.isNewUser = action.payload;
      },
      prepare: (value) => ({ payload: value }),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserDataFirebase.fulfilled, (state, action: any) => {
      return {
        ...state,
        ...action.payload,
      };
    });

    builder.addCase(setUserCurrentGame.fulfilled, (state, action: any) => {

    });

    builder.addCase(logout.fulfilled, (state, action: any) => {

    });
  },
});

export const {
  userLoggedOut,
  setIsNewUser,
} = UserSlice.actions;

export default UserSlice.reducer;
