/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect } from 'react';
import { useAppDispatch, useCookies } from '../hooks';
import jwt_decode from 'jwt-decode';
import { setUserDataFirebase } from '../reducers/auth/UserSlice';
import FirebaseService from '../services/FirebaseService';


interface AuthProps {
  children?: React.ReactNode;
  state?: any;
}

interface JwtPayload {
  firebaseUserId: string;
}

const Auth = ({ children }: AuthProps) => {
  const dispatch = useAppDispatch();
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const { getCookie } = useCookies();

  const firebaseCheck = () => {
    return new Promise((resolve) => {
      FirebaseService.init((success: boolean) => {
        if (!success) {
          resolve(false);
        }
        resolve(true);
      });
    });
  };

  const loadToken = async () => {
    const searchParams = new URLSearchParams(window.location.search.substring(1));
    const encryptedToken = getCookie('Firebase') || searchParams.get('token');
    if (!encryptedToken) {
      console.log('No user cookie found');
      return false;
    }
    let { firebaseUserId } = jwt_decode(encryptedToken) as JwtPayload;
    const authUser = { uid: firebaseUserId };
    FirebaseService.onConnected(authUser.uid);
    try {
      await FirebaseService.getUserData(authUser.uid);
      await dispatch(setUserDataFirebase({ authUser }));
      return true;
    } catch (error) {
      console.error('Error loading user data: ', error);
      return false;
    }
  };

  useEffect(() => {
    async function initLoad() {
      const firebaseCheckResult = await firebaseCheck();
      if (!firebaseCheckResult) return false;
      const loadTokenResult = await loadToken();
      if (!loadTokenResult) return false;
      setWaitAuthCheck(false);
    }
    if (waitAuthCheck) {
      initLoad();
    }
  });

  return (
    <>
      {waitAuthCheck ? (
        <>
        </>
      )
        : (
          <>
            {children}
          </>
        )
      }
    </>
  );
};

export default Auth;
