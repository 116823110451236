import "@twentyfour7/toibox-avatar/dist/index.css";
import React from "react";
import { AvatarEditor } from "@twentyfour7/toibox-avatar";
import states from '../reducers/states';
import { useAppSelector } from '../hooks';

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_AUTH_API_KEY,
  appId: process.env.REACT_APP_MAIN_APP_ID,
  authDomain: process.env.REACT_APP_MAIN_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_MAIN_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_MAIN_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_MAIN_PROJECT_ID,
  storageBucket: process.env.REACT_APP_MAIN_STORAGE_BUCKET,
};

const Avatar: React.FC = () => {
  const { user } = useAppSelector(states.auth);
  const userId = user.uid || process.env.REACT_APP_DEV_USER_ID || "";
  const params = new URLSearchParams(window.location.search.substring(1))

  return (
    <AvatarEditor
      userId={userId}
      firebaseConfig={FIREBASE_CONFIG}
      accessToken={""}
      backgroundColor={'#212121'}
      editor={params.get('editor') === 'yes'}
      onSaveCallback={() => console.log()}
    // eslint-disable-next-line @typescript-eslint/indent
    />
  );
};

export default Avatar;
